<template>
  <div id="time-line">
    <div class="timeline">
      <CardHeader ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <template v-if="isLoading">
          <AppLoading />
        </template>
        <template v-else>
          <div class="timeline__content">
            <div v-for="(tweet, key) of firebaseListTweet" :key="key">
              <TimelineNew
                :propsNew="tweet"
                :propsTab="offerTab"
                :tabindex="key"
                :propsIndex="key"
                :propsLength="firebaseListTweet.length"
                :propsScore="
                  listScores[tweet.userId.id]
                    ? listScores[tweet.userId.id]
                    : null
                "
                :propsAction="false"
                @handleBlock="blockUser($event)"
                @like="like"
              />
            </div>
            <scroll-loader :loader-method="getList" :loader-disable="disable">
            </scroll-loader>
          </div>
        </template>
      </div>
      <CardFooter ref="footer" prefer="timeline" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader.vue";
import TimelineNew from "@/views/Common/Timeline/TimelineNew.vue";
export default {
  name: "Timeline",
  components: {
    CardHeader,
    CardFooter,
    TimelineNew
  },
  data() {
    return {
      disable: false,
      isShowSlider: true,
      offerTab: 1, // 1: すべて; 2: お気に入り
      headerTitle: {
        text: "つぶやき",
        isShowTitle: true,
        isShowLogo: false
      },
      tweetEdit: null,
      numberNotification: 0
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      firebaseListTweet: "firebase/listTweetsProfile",
      authUser: "auth/user",
      listScores: "auth/listScores"
    })
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    this.$store.dispatch("auth/getListScores");
    await this.resetListTweet();
    this.getListProfileTweets({
      userId: this.$route.query.userId
    });
    this.$store.dispatch("common/setIsLoading", false);
  },
  watch: {
    firebaseListTweet(newVal, oldVal) {
      if (newVal.length - oldVal.length < 10) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
  },
  methods: {
    ...mapActions("firebase", [
      "getListProfileTweets",
      "resetListTweet",
      "likeTweet"
    ]),

    getList() {
      if (this.firebaseListTweet[this.firebaseListTweet.length - 1]) {
        this.getListProfileTweets({
          userId: this.$route.query.userId,
          lastTweet: this.firebaseListTweet[this.firebaseListTweet.length - 1]
            .id
        });
      }
    },
    async like(e) {
      let action = "like";
      if (e.is_like) {
        action = "unlike";
      }
      await this.likeTweet({
        userId: this.authUser.user_id,
        tweetId: e.id,
        action: action
      });
      await this.getListProfileTweets({
        userId: this.$route.query.userId,
        like: true
      });
    },
    resetTweetContent() {
      this.tweetEdit = null;
      document.getElementById("content-scroll").scrollIntoView();
    },
    blockUser(userId) {
      this.getUserIdsBlock();
      this.hiddenUserBlocked(userId);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-slider {
  display: none;
}
.list {
  margin-top: 20px;
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .image-info {
    padding-right: 8px;
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      border-radius: 100%;
      border: 2px solid #464d77;
      margin-bottom: 5px;
      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
    }
    span {
      font-family: "Hiragino Kaku Gothic Pro W3";
    }
  }
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 42px 19px 60px 19px;
}

.timeline {
  &__content {
    padding: 20px 0;
  }
}

@media screen and (min-width: 1200px) {
  .icon-slider {
    cursor: pointer;
    display: block;
    &-next {
      left: 0px;
      z-index: 1;
      top: 15px;
      transform: rotate(180deg);
    }
    &-pre {
      right: 0;
      z-index: 1;
      top: 15px;
    }
  }
  .list {
    margin-top: 20px;
    .image-info {
      padding-right: 20px;
    }
  }
  .content-scroll {
    // height: calc(100vh - 152px);
    // padding: 0 35px;
    padding: 62px 35px 91px 35px;
  }
  .timeline {
    &__content {
      padding: 20px 231px;
      padding-bottom: 50px;
    }
  }
}
</style>
